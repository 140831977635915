import { async } from '@angular/core/testing';
import { AuthService } from './../../services/auth/auth.service';
import { ApiService } from './../../services/api/api.service';
import { api } from './../../../environments/environment.prod';
import { Component, OnInit } from "@angular/core";
import { LoadingController, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
	selector: "app-change-store-user",
	templateUrl: "./change-store-user.component.html",
	styleUrls: ["./change-store-user.component.scss"],
})
export class ChangeStoreUserComponent implements OnInit {
	usersData: any = []
	loader: boolean = true
	storesData: any = {}
	storesList: any = []
	constructor(
		private auth: AuthService,
		public loadingCtrl: LoadingController,
		private api: ApiService,
		private modalCtrl: ModalController,
		
	) { }

	ngOnInit() { 
		this.getDataStores()
	}

	
    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 5000,
            toast: true
        });
    }

	
	// Get data initial for users and alls stores
	async getDataStores ( ) {

		// Filter the stores by country
		this.api.getRef("stores").ref.where("countryCode", "==", this.auth.countryCode).get().then(async(response)=> {
			for ( let i=0; i < response.size; i++ ) {
				// Get list for assignate at user
				this.storesData[response.docs[i].id] = {
					name: response.docs[i].data().name != undefined ? response.docs[i].data().name : "" ,
					id: response.docs[i].id,
					code: response.docs[i].data().code_store != undefined ? response.docs[i].data().code_store : ""
				}

				//Get list for show in ion list
				this.storesList.push({
					name: response.docs[i].data().name != undefined ? response.docs[i].data().name : "" ,
					id: response.docs[i].id,
					code: response.docs[i].data().code_store != undefined ? response.docs[i].data().code_store : ""
				})
			}	
		}).catch((error)=>{
			console.log(error.message)
		})
		// Filter user assignate to country
		this.api.getRef('users-stores').ref.where("countryCode", "==", this.auth.countryCode).get().then(async(response)=>{
			for ( let i=0; i < response.size; i++ ) {
				if ( response.docs[i].data().hasOwnProperty("role") && response.docs[i].data().hasOwnProperty("store") && response.docs[i].data().store != undefined){
					this.usersData.push({
						id: response.docs[i].id,
						email: response.docs[i].data().email != undefined ? response.docs[i].data().email : "",
						rol: response.docs[i].data().role != undefined ?  response.docs[i].data().role : "",
						nameStore: this.storesData[response.docs[i].data().store]["name"] != undefined ?  this.storesData[response.docs[i].data().store]["name"] : "" ,
						storeKey: this.storesData[response.docs[i].data().store]["id"] != undefined ? this.storesData[response.docs[i].data().store]["id"] : "",
						storeCode: this.storesData[response.docs[i].data().store]["code"] != undefined ? this.storesData[response.docs[i].data().store]["code"] : ""
					})
				}
			}	
			this.loader = false
		}).catch((error)=>{
			console.log(error.message)
		})

	}

	// Assignate to new store
	async newStoreForAccount (e, user) {
		for ( let i = 0; i < this.usersData.length; i ++) {
			if ( this.usersData[i].id == user.id ) {
				this.usersData[i]["newStore"] = e
				this.usersData[i]["oldStoreKey"] = this.usersData[i]["storeKey"] 
				this.usersData[i]["storeKey"] = e
				this.usersData[i]["nameStore"] = this.storesData[e]["name"] 
				this.usersData[i]["storeCode"] = this.storesData[e]["code"] 
				break;
			}
		}
	}

	//Save changes in data
	async saveNewChanges () {
		this.loaderComponent().then((loader) => {
			loader.present().then(async()=>{
				for( let i = 0; i < this.usersData.length; i++ ) {
					if ( this.usersData[i].hasOwnProperty("newStore") ) {
						// Update information account
						let user = {
							store:  this.usersData[i].newStore
						}
						this.api.updateDocument("users-stores", this.usersData[i].id, user)

						// Update old store
						let stores:any = await this.api.getDocument("stores", this.usersData[i].oldStoreKey).then( async(response)=> {
							return await response
						})
						if ( Array.isArray(stores.users)) {
							let userStores = stores.users.filter((item)=> item.email != this.usersData[i].email )
							this.api.updateDocument("stores", this.usersData[i].oldStoreKey, {users: userStores})
						}


						// Update new store
						let storesNew:any = await this.api.getDocument("stores", this.usersData[i].newStore).then( async(response)=> {
							return await response
						})
						
						if ( Array.isArray(storesNew.users)) {
							storesNew.users.push({
								email: this.usersData[i].email,
								role: this.usersData[i].rol

							})
							this.api.updateDocument("stores",  this.usersData[i].newStore, {users: storesNew.users})
						}

						loader.dismiss()
						this.modalCtrl.dismiss()
						
                        this.mensajeAlerta('success', 'Usuario cambiado de tienda');

					}
				}
			})
		})
	}

	
    async loaderComponent() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }
}
