export const RoleSevenSV = [
    {
        title: 'Monitor V2',
        url: '/index/monitor-v2',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Dashboard',
        url: '/index/section/dashboard',
        base: '',
        icon: 'home',
        alert: false
    },
    {
        title: 'Buscador Producto',
        url: '/index/search-brick',
        base: '',
        icon: 'search-outline',
        alert: false
    },
    {
        title: 'Vinos',
        url: '/index/section/wines',
        base: '',
        icon: 'icon-wine',
        customIcon: true,
        alert: false
    },
    {
        title: 'Destilados',
        url: '/index/section/liquors',
        base: '',
        icon: 'icon-liquor',
        customIcon: true,
        alert: false
    },
    {
        title: 'Cervezas',
        url: '/index/section/beers',
        base: '',
        icon: 'icon-beer',
        customIcon: true,
        alert: false
    },
    {
        title: 'No alcoholicas',
        url: '/index/section/non-alcoholic',
        base: '',
        icon: 'icon-non-alcoholic',
        customIcon: true,
        alert: false
    },
    {
        title: 'Market',
        url: '/index/section/extras',
        base: '',
        icon: 'icon-snack',
        customIcon: true,
        alert: false
    },
    {
        title: 'Kits',
        url: '/index/section/kits',
        base: '',
        icon: 'file-tray-stacked',
        alert: false
    },
    {
        title: 'Club Barlleno',
        url: '/index/section/club',
        base: '',
        icon: 'basket',
        alert: false
    },
    {
        title: 'Proveedores',
        url: '/index/suppliers',
        base: '',
        icon: 'git-branch-outline',
        alert: false
    },
    {
        title: 'Colaboradores',
        url: '/index/delivery',
        base: '',
        icon: 'person-add-outline',
        alert: false
    },
    {
        title: 'Calendario Trabajo',
        url: '/index/shedule-collaborator',
        base: '',
        icon: 'calendar-outline',
        alert: false
    },
    {
        title: 'Reportes',
        url: '/index/reports-v3',
        base: '',
        icon: 'newspaper-outline',
        alert: false
    },
    {
        title: 'Configuraciones',
        url: '/index/settings',
        base: '',
        icon: 'cog',
        alert: false
    },
    {
        title: 'Asistencia Técnica',
        url: '/index/support',
        base: '',
        icon: 'build-outline',
        alert: false
    }
]