import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { Sort } from './sort';


@Directive({
    selector: '[appSort]'
})
export class SortDirective {

    @Input() appSort: Array<any>;
    constructor(private targetElem: ElementRef) { }

    @HostListener("click")
    sortData() {
        const elem = this.targetElem.nativeElement;
        const order = elem.getAttribute("data-order");
        const type = elem.getAttribute("data-type");
        const property = elem.getAttribute("data-name");

        const sort = new Sort();
        this.appSort.sort(sort.startSort(property, order, type));
        elem.setAttribute("data-order", order == "desc" ? "asc" : "desc");
        elem.children[0].setAttribute("name", order == "desc" ? "chevron-up-outline" : "chevron-down-outline");
    }
}