import { AddMixersComponent } from "src/app/components/add-mixers/add-mixers.component";
import { UploadService } from "../../services/uploads/upload.service";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoadingController, NavParams, ModalController } from "@ionic/angular";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { AddReferenceComponent } from "../add-reference/add-reference.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { HttpClient } from "@angular/common/http";
import { SwalService } from "src/app/services/alert/swal.service";
import { ClipboardService } from "ngx-clipboard";
import { SapService } from "src/app/services/sap/sap.service";
import { AddReferenceMixoComponent } from "src/app/components/add-reference-mixo/add-reference-mixo.component";
@Component({
  selector: "app-update-brick",
  templateUrl: "./update-brick.component.html",
  styleUrls: ["./update-brick.component.scss"],
})
export class UpdateBrickComponent implements OnInit {
  brickForm: FormGroup;
  brick_key: any;
  brick: any;
  brick_image: any = false;
  presentationName: any;
  on_presentationName: any;
  presentations: any = [];
  on_presentations: any = [];
  category: any;
  type: any = "brick";
  recommended: any = [];
  recommendedMixology: any = [];
  referenceKits: any = [];
  isToggleBtnChecked: any = "";
  creation_date: any;
  oldVisible: any;
  // supplier dinamic DB
  supplier: any;
  nivel: any;

  listImage: string[] = [];
  listImageFile: File[] = [];
  newListReference: any = [];
  listReference: any = [];
  listTempReference: any = [];
  listKeywords: string[] = [];

  mainImage: string;
  mainImageFile: File;
  wordKeys: string = "";

  wineisActive: boolean = false;

  tinyConfs: any = {
    height: 250,
    plugins:
      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons",
    menubar: "file edit view insert format tools table help",
    toolbar:
      "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
    toolbar_sticky: true,
    importcss_append: true,
    noneditable_noneditable_class: "mceNonEditable",
    toolbar_mode: "sliding",
    contextmenu: "link table",
    templates: [
      {
        title: "New Table",
        description: "creates a new table",
        content:
          '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
      },
    ],
  };

  // config options select suppliers
  optionsSuppliers = [];
  unit_system = [];
  presentation_unidades: any;
  category_key: any;

  categoryBase: any[] = [""];

  categories: any = {
    level1: [],
  };

  // trade entity (superB)
  superb_PresentationName: any;
  superb_on_presentationName: any;
  // collections superb
  superbPresentations: any = [];
  superb_on_presentations: any = [];

  clipboard: any = "";
  isCopied1: boolean;

  constructor(
    public api: ApiService,
    public sap: SapService,
    private uploadService: UploadService,
    public formBuilder: FormBuilder,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public auth: AuthService,
    private http: HttpClient,
    public modalController: ModalController,
    private SwalService: SwalService,
    private _clipboardService: ClipboardService
  ) {
    this.brick = navParams.get("brick");
    this.category_key = navParams.get("category_key");

    if (this.brick) {
      this.clipboard = this.brick.$key;
      this.oldVisible = this.brick.visible;
      this.supplier = this.brick.supplier;
      this.nivel = this.brick.nivel;
      if (this.brick.referenceKits) {
        this.referenceKits = this.brick.referenceKits;
      }
    }

    if (navParams.get("type")) {
      this.type = navParams.get("type");
    }

    // original - antes de integrar las nuevas propiedades del formulario
    // this.brickForm = this.formBuilder.group({
    //     name: [this.brick.name, [Validators.required]],
    //     description: [this.brick.description, [Validators.required]],
    //     visible: [this.brick.visible, [Validators.required]],
    //     type: ['brick', [Validators.required]],
    //     on_trade: [this.brick.on_trade, [Validators.required]],
    //     url_link: [this.brick.url_link],
    //     supplier: [this.brick.supplier],
    //     bar_code: [this.brick.bar_code],
    //     position: [this.brick.position],
    //     free_delivery: [this.brick.free_delivery],
    // });
    this.brickForm = this.formBuilder.group({
      brand: [this.brick.brand, [Validators.required]],
      name: [this.brick.name, [Validators.required]],
      line: [this.brick.line, [Validators.required]],
      matriz: [this.brick.matriz, [Validators.required]],
      description: [this.brick.description],
      visible: [this.brick.visible, [Validators.required]],
      type: [this.brick.type, [Validators.required]],
      on_trade: [this.brick.on_trade, [Validators.required]],
      url_link: [this.brick.url_link],
      supplier: [this.brick.supplier],
      nivel: [this.brick.nivel],
      bar_code: [this.brick.bar_code],
      system_code: [this.brick.system_code],
      position: [this.brick.position],
      free_delivery: [this.brick.free_delivery],
      keysWord: [this.brick["keysWord"]],
      // volume: [this.brick.volume ? this.brick.volume : { value: '', disabled: true }],
      // boolVolume: [this.brick.boolVolume ? this.brick.boolVolume : false],
      alcohol: [
        this.brick.alcohol ? this.brick.alcohol : { value: "", disabled: true },
      ],
      boolAlcohol: [this.brick.boolAlcohol ? this.brick.boolAlcohol : false],
      detalles: this.formBuilder.group({
        region: [{ value: "", disabled: true }],
        temperatura: [{ value: "", disabled: true }],
        maridaje: [{ value: "", disabled: true }],
        cristaleria: [{ value: "", disabled: true }],
        notasCatacion: [{ value: "", disabled: true }],
        boolRegion: [false],
        boolTemperatura: [false],
        boolMaridaje: [false],
        boolCristaleria: [false],
        boolNotasCata: [false],
      }),
      activarVino: [false],
    });
    // this.categoryBase = this.brick.categoryBase ? this.brick.categoryBase : ['']
    this.loadImagesCarrousel(this.brick);
    this.loadDataWine(this.brick);
    this.loadDetails(this.brick);
    this.api.getAllDocuments("categories").then((response) => {
      response.forEach((element) => {
        this.categories.level1.push({
          path: "categories",
          id: element.$key,
          name: element.name,
        });
      });
    });

    if (this.type == "brick") {
      this.api
        .getRef(`bricks/${this.brick.$key}/presentations`)
        .ref.onSnapshot((snapshots) => {
          snapshots.docChanges().forEach((element) => {
            if (element.type == "added") {
              let presentation = element.doc.data();
              presentation.$key = element.doc.id;
              this.presentations.push(presentation);
            }
          });
        });

      this.api
        .getRef(`bricks/${this.brick.$key}/on_presentations`)
        .ref.onSnapshot((snapshots) => {
          snapshots.docChanges().forEach((element) => {
            if (element.type == "added") {
              let presentation = element.doc.data();
              presentation.$key = element.doc.id;
              this.on_presentations.push(presentation);
            }
          });
        });

      // inicio superb escuchador de presentaciones
      this.api
        .getRef(`bricks/${this.brick.$key}/superb_presentations`)
        .ref.onSnapshot((snapshots) => {
          snapshots.docChanges().forEach((element) => {
            if (element.type == "added") {
              let presentation = element.doc.data();
              presentation.$key = element.doc.id;
              this.superbPresentations.push(presentation);
            }
          });
        });

      this.api
        .getRef(`bricks/${this.brick.$key}/superb_on_presentations`)
        .ref.onSnapshot((snapshots) => {
          snapshots.docChanges().forEach((element) => {
            if (element.type == "added") {
              let presentation = element.doc.data();
              presentation.$key = element.doc.id;
              this.superb_on_presentations.push(presentation);
            }
          });
        });
      // fin superb escuchador de presentaciones
    }
    this.getReferences();
    this.getReferencesKits();
    this.getReferencesMixology();
    this.optionsSuppliers.unshift();
    // recuperando informacion de los Proveedores Barlleno
    this.api.getAllDocuments(`suppliers`).then((data) => {
      if (data) {
        data.forEach((element) => {
          if (
            element["countryCode"] == this.auth.countryCode &&
            element["active"] == true
          ) {
            let arr = [];
            arr["id"] = element.$key;
            arr["name"] = element.name;
            this.optionsSuppliers.push(arr);
          }
        });
      }
    });

    this.getUnitSystem();
  }

  ngOnInit() {
    if (this.categories["level1"].length > 0) {
      this.categories["level1"] = {
        level1: [],
      };
    }
    this.categoryBase =
      this.brick.categoryBase != undefined
        ? this.brick.categoryBase
        : this.categoryBase;
    if (this.categoryBase.length > 0) {
      this.categories = {
        level1: [],
        level2: [],
        level3: [],
        level4: [],
      };
    }

    for (let i = 0; i < this.categoryBase.length; i++) {
      this.getDataCategories(this.categoryBase[i], i + 1);
    }
  }

  changeCheckBox(e, element, level) {
    if (!this.categories.hasOwnProperty("level" + (level + 1))) {
      this.getDataCategories(e, level);
      if (this.categoryBase.length == 1 && this.categoryBase[0] == "") {
        this.categoryBase = [];
      }
      this.categoryBase.push(e);
    } else {
      if (this.categoryBase.length == 1 && this.categoryBase[0] == "") {
        this.categoryBase = [];
      }
      if (element + level == "level1") {
        this.categoryBase.length = 0;
        this.getDataCategories(e, level);
      } else if (element + level == "level2") {
        this.categoryBase.length = 1;
        this.getDataCategories(e, level);
      } else if (element + level == "level3") {
        this.categoryBase.length = 2;
        this.getDataCategories(e, level);
      } else if (element + level == "level4") {
        this.categoryBase.length = 3;
        this.getDataCategories(e, level);
      }
      this.categoryBase.push(e);
    }
  }

  getDataCategories(e, level) {
    this.categories["level" + (level + 1)] = [];

    if (this.categories["level" + (level + 1)].length > 0) {
      this.categories["level" + (level + 1)].length = 0;
    }

    this.api
      .getRef("bricks")
      .ref.where("countryCode", "==", this.auth.countryCode)
      .where("storeKey", "==", this.auth.storeKey)
      .where("type", "==", "category")
      .where("category", "==", e)
      .get()
      .then((response) => {
        if (response.size > 0) {
          this.categories["level" + (level + 1)].length = 0;
          response.forEach((data: any) => {
            let item = data.data();
            this.categories["level" + (level + 1)].push({
              path: "bricks",
              id: data.id,
              name: item.name,
            });
          });
        }
      });
  }

  getUnitSystem() {
    this.api
      .getRef("catalogue")
      .ref.where("type", "==", "unit_system")
      .orderBy("name", "asc")
      .get()
      .then((data) => {
        data.forEach((element) => {
          if (element) {
            let unit = element.data();
            unit.$key = element.id;
            this.unit_system.push(unit);
          }
        });
      });
  }

  getReferences() {
    this.recommended = [];
    this.api
      .getRef(`recommended/`)
      .ref.where("brick", "==", this.brick.$key)
      .get()
      .then((snapshots) => {
        snapshots.forEach((element) => {
          let recommended = element.data();
          recommended.$key = element.id;

          this.api.getDocument("bricks", element.data().reference).then(
            (data) => {
              recommended.brick = data;
              this.recommended.push(recommended);
              console.log(this.recommended);
            },
            (err) => {
              this.api.deleteDocument("bricks", recommended.$key);
            }
          );
        });
      });
  }

  getReferencesMixology() {
    this.recommendedMixology = [];
    this.api
      .getRef(`recommended/`)
      .ref.where("brick", "==", this.brick.$key)
      .get()
      .then((snapshots) => {
        snapshots.forEach((element) => {
          console.log(element.data());

          let recommendedMixology = element.data();
          recommendedMixology.$key = element.id;

          this.api.getDocument("explore", element.data().reference).then(
            (data) => {
              recommendedMixology.brick = data;
              this.recommendedMixology.push(recommendedMixology);
              console.log(this.recommendedMixology);
            },
            (err) => {
              this.api.deleteDocument("explore", recommendedMixology.$key);
            }
          );
        });
      });
  }

  async loader() {
    return await this.loadingCtrl.create({
      spinner: "lines-small",
      animated: true,
      mode: "ios",
      translucent: false,
      cssClass: "custom-class custom-loading",
    });
  }

  uploadedImages(file) {
    this.brick_image = {
      name: file.file.name,
      src: file.src,
    };
  }

  removeImages() {
    this.brick_image = false;
  }

  addBrick() {
    this.loader().then((loader) => {
      loader.present().then(async () => {
        // validaciones de seccion detalle del brick
        if (this.brickForm.get("on_trade").value == undefined) {
          this.brickForm.get("on_trade").setValue(false);
        }

        if (this.reviewValidations() == false) {
          loader.dismiss();
          return;
        }

        let obj = [];
        for (let index = 0; index < this.referenceKits.length; index++) {
          const element = this.referenceKits[index];
          obj.push({
            brick: this.brick.$key,
            reference: element.reference,
            visible: element.visible,
          });
        }
        this.brick.referenceKits = obj;

        this.brick = Object.assign(this.brick, this.brickForm.value);
        this.brick.brand = this.brickForm.value.brand;
        this.brick.name = this.brickForm.value.name;
        this.brick.line = this.brickForm.value.line;
        this.brick.description = this.brickForm.value.description;
        this.brick.visible = this.brickForm.value.visible;
        this.brick.bar_code = this.brickForm.value.bar_code;
        // this.brick.supplier = this.brickForm.value.supplier;
        this.brick.supplier = this.supplier;
        this.brick.free_delivery = this.brickForm.value.free_delivery;
        this.brick.on_trade = this.brickForm.value.on_trade;
        this.brick.url_link = this.brickForm.value.url_link;

        // nuevos campos
        this.brick.nivel = this.nivel;
        this.brick.matriz = this.brickForm.value.matriz;
        this.brick.system_code = this.brickForm.value.system_code;

        this.brick["keysWord"] = this.brickForm.value.keysWord;
        // agregando nuevos UX detalle del brick en objeto a guardar en la base
        // this.brick.boolAlcohol = this.brickForm.value.boolAlcohol;
        // this.brick.alcohol = this.brickForm.value.alcohol;
        // this.brick.boolVolume = this.brickForm.value.boolVolume;
        // this.brick.volume = this.brickForm.value.volume;
        // this.brick.detalles.boolRegion = this.brickForm.value.;
        // this.brick. = this.brickForm.value.;
        Object.keys(this.brick).forEach((key) =>
          this.brick[key] === undefined ? delete this.brick[key] : {}
        );
        // return

        // validaciones de datos
        if (this.brick.brand == "" || this.brick.brand == undefined) {
          this.mensajeAlerta("error", "Favor completar Nombre de Marca");
          loader.dismiss();
          return;
        }
        if (this.brick.name == "" || this.brick.name == undefined) {
          this.mensajeAlerta("error", "Favor completar Nombre de Producto");
          loader.dismiss();
          return;
        }
        if (this.brick.line == "" || this.brick.line == undefined) {
          this.mensajeAlerta("error", "Favor completar Linea de Producto");
          loader.dismiss();
          return;
        }
        if (this.brick.image == "" || this.brick.image == undefined) {
          this.mensajeAlerta("error", "Favor completar Imagen de Producto");
          loader.dismiss();
          return;
        }
        if (
          this.brick.description == "" ||
          this.brick.description == undefined ||
          this.brick.description.length <= 3
        ) {
          this.mensajeAlerta(
            "error",
            "Favor completar Descripción del Producto"
          );
          loader.dismiss();
          return;
        }
        if (
          this.brick.matriz == "" ||
          this.brick.matriz == undefined ||
          this.brick.matriz.length <= 4
        ) {
          this.mensajeAlerta("error", "Favor completar Matriz del Producto");
          loader.dismiss();
          return;
        }
        if (
          !(this.presentations.length > 0) &&
          !(this.on_presentations.length > 0)
        ) {
          this.mensajeAlerta(
            "error",
            "Favor completar Presentaciones del Producto"
          );
          loader.dismiss();
          return;
        } else {
          // para barlleno normal
          const flagBoolean = await this.presentations.map((item) => {
            let price = item.price;
            if (price) return price.toString().indexOf(",") > -1;
            else return false;
          });
          if (flagBoolean.includes(true)) {
            this.mensajeAlerta(
              "error",
              "Precio no valido, reemplazar (,) por (.)"
            );
            loader.dismiss();
            return;
          }

          // para barlleno ON
          const flagBooleanON = await this.on_presentations.map((item) => {
            let price = item.price;
            if (price) return price.toString().indexOf(",") > -1;
            else return false;
          });
          if (flagBooleanON.includes(true)) {
            this.mensajeAlerta(
              "error",
              "Precio no valido, reemplazar (,) por (.)"
            );
            loader.dismiss();
            return;
          }

          // validacion para seleccion de presentacion principal
          // para barlleno normal

          const flagBooleanMain = await this.presentations.map((item) => {
            if (item.main == true) return item.main;
            else return false;
          });

          if (!flagBooleanMain.includes(true)) {
            this.mensajeAlerta(
              "error",
              "Favor seleccionar Presentación Principal"
            );
            loader.dismiss();
            return;
          }
        }
        // fin validaciones de datos`

        if (!this.brickForm.get("boolAlcohol").value) {
          this.brick.alcohol = "";
        }

        if (!this.brick.position) {
          this.brick.position = 0;
        }

        // cargando imagenes en base Storage
        if (!this.brick.imagen_carrusel) {
          // let downloadURL =  {0:'',1:'',2:''}
          this.brick["imagen_carrusel"] = {
            downloadURL: [this.brick.image.downloadURL, "", ""],
          };
          // let downloadURL =  {0:'',1:'',2:''}
          // this.brick['imagen_carrusel'] = [downloadURL]
        }
        if (this.listImageFile[0]) {
          await this.uploadService
            .uploadImagesByPosition(this.category_key, this.listImageFile[0])
            .then(async (response) => {
              this.brick.imagen_carrusel["downloadURL"][0] = await this.brick
                .image.downloadURL;
            });
        }

        if (this.listImageFile[1]) {
          await this.uploadService
            .uploadImagesByPosition(this.category_key, this.listImageFile[1])
            .then(async (response) => {
              this.brick.imagen_carrusel["downloadURL"][1] = await response;
            });
        }

        if (this.listImageFile[2]) {
          await this.uploadService
            .uploadImagesByPosition(this.category_key, this.listImageFile[2])
            .then(async (response) => {
              this.brick.imagen_carrusel["downloadURL"][2] = await response;
            });
        }
        if (this.wineisActive == false && this.brick.hasOwnProperty("vino")) {
          this.brick.vino = null;
        }

        if (this.brick.detalles) {
          //hasOwnProperty
          if (
            this.brick.detalles.hasOwnProperty("boolRegion") &&
            this.brick.detalles.boolRegion == true
          ) {
            if (
              this.brick.detalles.hasOwnProperty("region") &&
              !(this.brick.detalles["region"].length > 0)
            ) {
              this.brick.detalles.boolRegion = false;
            }
          }
          if (
            this.brick.detalles.hasOwnProperty("boolTemperatura") &&
            this.brick.detalles.boolTemperatura == true
          ) {
            if (
              this.brick.detalles.hasOwnProperty("temperatura") &&
              !(this.brick.detalles["temperatura"] > 0)
            ) {
              this.brick.detalles.boolTemperatura = false;
            }
          }
          if (
            this.brick.detalles.hasOwnProperty("boolMaridaje") &&
            this.brick.detalles.boolMaridaje == true
          ) {
            if (
              this.brick.detalles.hasOwnProperty("maridaje") &&
              !(this.brick.detalles["maridaje"].length > 0)
            ) {
              this.brick.detalles.boolMaridaje = false;
            }
          }
          if (
            this.brick.detalles.hasOwnProperty("boolCristaleria") &&
            this.brick.detalles.boolCristaleria == true
          ) {
            if (
              this.brick.detalles.hasOwnProperty("cristaleria") &&
              !(this.brick.detalles["cristaleria"].length > 0)
            ) {
              this.brick.detalles.boolCristaleria = false;
            }
          }
          if (
            this.brick.detalles.hasOwnProperty("boolNotasCata") &&
            this.brick.detalles.boolNotasCata == true
          ) {
            if (
              this.brick.detalles.hasOwnProperty("notasCatacion") &&
              !(this.brick.detalles["notasCatacion"].length > 0)
            ) {
              this.brick.detalles.boolNotasCata = false;
            }
          }
        }

        // await new Promise(r => setTimeout(r, 500));

        await this.api
          .getRef("bricks")
          .ref.where("brick", "==", this.brick.$key)
          .where("type", "==", "reference")
          .get()
          .then((data) => {
            data.forEach((element) => {
              this.api.updateDocument("bricks", element.id, {
                visible: this.brick.visible,
              });
            });
          });
        if (this.brick.reference == undefined) {
          this.brick.position = this.brickForm.value.position;
        }

        if (this.categoryBase.length > 0) {
          this.brick["categoryBase"] = this.categoryBase;
        }

        // el producto se coloco en invisible

        if (this.brick.imagen_carrusel["downloadURL"]) {
          let imagen_carrusel = { downloadURL: [] };
          for (
            let i = 0;
            i < this.brick.imagen_carrusel["downloadURL"].length;
            i++
          ) {
            if (this.brick.imagen_carrusel["downloadURL"][i] == "") {
              delete this.brick.imagen_carrusel["downloadURL"][i];
            } else {
              imagen_carrusel["downloadURL"].push(
                this.brick.imagen_carrusel["downloadURL"][i]
              );
            }
          }
          this.brick.imagen_carrusel["downloadURL"] =
            imagen_carrusel["downloadURL"];
        }
        // if ((this.brick.visible != this.oldVisible && this.brick.visible == false) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
        if (
          this.brick.visible != this.oldVisible &&
          this.brick.visible == false &&
          this.auth.countryCode
        ) {
          let presentationOld = this.presentations;
          this.creation_date = new Date();
          let nameBrick = this.brick.name;
          // remover emoji de los textos
          nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "");
          this.presentations.forEach((element) => {
            element.inventory = Number(0);
            element["factorUnity"] = Number(
              element["factorUnity"] ? element["factorUnity"] : 1
            );
          });

          this.api
            .updateBrick(
              this.brick,
              this.presentations,
              this.on_presentations,
              this.brick_image.src,
              this.superbPresentations,
              this.superb_on_presentations
            )
            .then((data) => {
              loader.dismiss();
              this.SwalService.fire(
                "¡Listo!",
                "Tu producto ha sido actualizado",
                "success"
              );
              this.modalController.dismiss();
            });
          // this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryHidden&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
          //     .subscribe((response) => {
          //         this.presentations.forEach(element => {
          //             element.inventory = Number(0)
          //             element["factorUnity"] = Number(element["factorUnity"] ? element["factorUnity"] : 1)

          //         });

          //         this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
          //             loader.dismiss();
          //             this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
          //             this.modalController.dismiss();
          //         });
          //     })
          // } else if ((this.brick.visible != this.oldVisible && this.brick.visible == true) && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN')) {
        } else if (
          this.brick.visible != this.oldVisible &&
          this.brick.visible == true &&
          this.auth.countryCode
        ) {
          let presentationOld = this.presentations;
          this.creation_date = new Date();
          let nameBrick = this.brick.name;
          nameBrick = nameBrick.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "");
          this.api
            .updateBrick(
              this.brick,
              this.presentations,
              this.on_presentations,
              this.brick_image.src,
              this.superbPresentations,
              this.superb_on_presentations
            )
            .then((data) => {
              loader.dismiss();
              this.SwalService.fire(
                "¡Listo!",
                "Tu producto ha sido actualizado",
                "success"
              );
              this.modalController.dismiss();
            });
          // this.http.get(`https://mails.barlleno.app/mails_inventoryB64.php?mode=inventoryShow&presentations=${window.btoa(JSON.stringify(presentationOld))}&countryCode=${this.auth.countryCode}&role=${this.auth.role}&email=${this.auth.email}&creation_date=${window.btoa(this.creation_date)}&nameBrick=${window.btoa(nameBrick)}`)
          //     .subscribe((response) => {
          //         this.api.updateBrick(this.brick, this.presentations, this.on_presentations, this.brick_image.src, this.superbPresentations, this.superb_on_presentations).then(data => {
          //             loader.dismiss();
          //             this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
          //             this.modalController.dismiss();
          //         });
          //     })
        } else {
          this.presentations.forEach((element) => {
            element["factorUnity"] = Number(
              element["factorUnity"] ? element["factorUnity"] : 1
            );
          });
          this.api
            .updateBrick(
              this.brick,
              this.presentations,
              this.on_presentations,
              this.brick_image.src,
              this.superbPresentations,
              this.superb_on_presentations
            )
            .then((data) => {
              loader.dismiss();
              this.SwalService.fire(
                "¡Listo!",
                "Tu producto ha sido actualizado",
                "success"
              );
              this.modalController.dismiss();
            });
        }
      });
    });
  }

  updateExplore() {
    this.loader().then((loader) => {
      loader.present().then(() => {
        this.brick.brand = this.brickForm.value.brand;
        this.brick.name = this.brickForm.value.name;
        this.brick.description = this.brickForm.value.description;
        this.brick.visible = this.brickForm.value.visible;
        this.brick.position = this.brickForm.value.position;

        this.api
          .getRef("explore")
          .ref.where("brick", "==", this.brick.$key)
          .where("type", "==", "reference")
          .get()
          .then((data) => {
            data.forEach((element) => {
              this.api.updateDocument("explore", element.id, {
                visible: this.brick.visible,
              });
            });
          });

        this.api
          .updateExplore(this.brick, this.brick_image.src)
          .then((data) => {
            loader.dismiss();
            this.SwalService.fire(
              "¡Listo!",
              "Tu producto ha sido agregado",
              "success"
            );
            this.modalController.dismiss();
          });
      });
    });
  }

  addPresentation() {
    let obj;
    if (this.presentations.length == 0) {
      obj = {
        presentation: `${this.presentationName} ${this.presentation_unidades}`,
        price: 0,
        reward: 0,
        codeSap: "",
        bar_code: "",
        visible: true,
        main: true,
      };
    } else {
      obj = {
        presentation: `${this.presentationName} ${this.presentation_unidades}`,
        price: 0,
        reward: 0,
        codeSap: "",
        bar_code: "",
        visible: true,
        main: false,
      };
    }

    this.api
      .addDocument(`bricks/${this.brick.$key}/presentations`, obj)
      .then((data) => {
        this.presentationName = "";
        this.presentation_unidades = null;
      });
  }

  addOnPresentation() {
    this.api
      .addDocument(`bricks/${this.brick.$key}/on_presentations`, {
        presentation: this.on_presentationName,
        price: 0,
        codeSap: "",
        bar_code: "",
      })
      .then((data) => {
        this.on_presentationName = "";
      });
  }

  changeMain(presentation, $event) {
    this.presentations.forEach((element) => {
      element.main = false;
    });
    // presentation.visible = true;
    presentation.main = true;
  }

  changeMainCheck(namePresentation, presentation, $event) {
    this.presentations.forEach((element) => {
      if (element.presentation != namePresentation) {
        element.main = false;
      }
    });
  }

  changeInventoryVisible(presentation, $event) {
    const isChecked = $event.detail.checked;
    if (isChecked) {
      presentation.inventory_visible = true;
    } else {
      presentation.inventory_visible = false;
    }
  }

  changeVisible(presentation, $event) {
    const isChecked = $event.detail.checked;
    if (isChecked) {
      presentation.visible = true;
    } else {
      presentation.visible = false;
    }
  }

  changeVisibleOnTriger(on_presentations, presentation) {
    let thisPresentation = presentation["presentation"];
    var respFind = on_presentations.find(function (elem, index) {
      if (elem.presentation == thisPresentation) {
        return true;
      }
    });

    if (respFind && presentation.inventory > 0) {
      respFind.visible = presentation.visible;
    }
  }

  changeVisibleOn(presentation, $event) {
    const isChecked = $event.detail.checked;

    if (isChecked) {
      presentation.visible = true;
    } else {
      presentation.visible = false;
    }
  }

  // changeOnMain(presentation, $event) {
  //     this.on_presentations.forEach(element => {
  //         element.main = false;
  //     });
  //     presentation.main = true;

  // }

  changeOnMain(namePresentation, presentation, $event) {
    this.on_presentations.forEach((element) => {
      if (element.presentation != namePresentation) {
        element.main = false;
      } else {
        // element.main = true;
      }
    });
  }

  changePrice(presentation, $event) {
    const { price } = presentation;
    // if (price && price.includes(',')) {
    // price.replace(',', '.')
    // this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
    // return;
    // }
  }

  changePriceBin(presentation, $event) {
    const { price_bin } = presentation;
    // if (price_bin && price_bin.includes(',')) {
    //     price_bin.replace(',', '.')
    //     this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
    //     return;
    // }
  }

  removePresentation(presentation) {
    this.SwalService.fire({
      title: "Estás seguro que deseas eliminar?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, estoy seguro",
    }).then((result) => {
      if (result.value) {
        this.api
          .deleteDocument(
            `bricks/${this.brick.$key}/presentations/`,
            presentation.$key
          )
          .then((data) => {
            for (let index = 0; index < this.presentations.length; index++) {
              const element = this.presentations[index];
              if (element.$key == presentation.$key) {
                this.presentations.splice(index, 1);
              }
            }

            this.SwalService.fire(
              "Eliminado!",
              "La presentación ha sido eliminada",
              "success"
            );
          });
      }
    });
  }

  removeOnPresentation(presentation) {
    this.SwalService.fire({
      title: "Estás seguro que deseas eliminar?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, estoy seguro",
    }).then((result) => {
      if (result.value) {
        this.api
          .deleteDocument(
            `bricks/${this.brick.$key}/on_presentations/`,
            presentation.$key
          )
          .then((data) => {
            for (let index = 0; index < this.on_presentations.length; index++) {
              const element = this.on_presentations[index];
              if (element.$key == presentation.$key) {
                this.on_presentations.splice(index, 1);
              }
            }

            this.SwalService.fire(
              "Eliminado!",
              "La presentación ha sido eliminada",
              "success"
            );
          });
      }
    });
  }

  async addReference() {
    const modal = await this.modalController.create({
      component: AddReferenceComponent,
      cssClass: "addModal",
      componentProps: {
        category: this.category,

      },
    });
    modal.onDidDismiss().then((data) => {
      let referenciaKey = data.data.$key;
      if (!data.data.hasOwnProperty("$key")) {
        referenciaKey = data.data.objectID;
      }
      if (data.data) {
        let brick = {
          brick: this.brick.$key,
          visible: data.data.visible,
          reference: referenciaKey,
          type_reference: "bricks",
          account_email_create: this.auth.email,
          account_email_update_log: [
            {
              action: "createdReference",
              dateUpdate: new Date(),
              email: this.auth.email,
            },
          ],
        };

        this.loader().then((loader) => {
          loader.present().then(() => {
            this.api.addDocument("recommended", brick).then((data) => {
              loader.dismiss();
              this.getReferences();
              this.SwalService.fire(
                "¡Listo!",
                "Tu Referencia ha sido agregada",
                "success"
              );
            });
          });
        });
      }
    });
    return await modal.present();
  }
  async addReferenceMixology() {
    // return
    const modal = await this.modalController.create({
      component: AddReferenceMixoComponent,
      cssClass: "addModal",
      componentProps: {
        category: this.category,
      },
    });
    modal.onDidDismiss().then((data) => {
      let referenciaKey = data.data.$key;

      if (!data.data.hasOwnProperty("$key")) {
        referenciaKey = data.data.objectID;
      }
      if (data.data) {
        let brick = {
          brick: this.brick.$key,
          visible: data.data.visible,
          reference: referenciaKey,
          type_reference: "explore",
          account_email_create: this.auth.email,
          account_email_update_log: [
            {
              action: "createdReference",
              dateUpdate: new Date(),
              email: this.auth.email,
            },
          ],
        };
        this.loader().then((loader) => {
          loader.present().then(() => {
            this.api.addDocument("recommended", brick).then((data) => {
              loader.dismiss();
              this.getReferencesMixology();
              this.SwalService.fire(
                "¡Listo!",
                "Tu Referencia ha sido agregada",
                "success"
              );
            });
          });
        });
      }
    });
    return await modal.present();
  }

  async addReferenceKits() {
    const modal = await this.modalController.create({
      component: AddReferenceComponent,
      cssClass: "addModal",
      componentProps: {
        category: this.category,
      },
    });
    modal.onDidDismiss().then((data) => {
      let referenciaKey = data.data.$key;
      if (!data.data.hasOwnProperty("$key")) {
        referenciaKey = data.data.objectID;
      }
      if (data.data) {
        let brick = {
          brick: this.brick.$key,
          visible: data.data.visible,
          reference: referenciaKey,
          account_email_create: this.auth.email,
          account_email_update_log: [
            {
              action: "createdReference",
              dateUpdate: new Date(),
              email: this.auth.email,
            },
          ],
        };
        this.referenceKits.push(brick);
        this.getReferencesKits();
      }
    });
    return await modal.present();
  }

  getReferencesKits() {
    let referenceKitsAux = [];
    this.referenceKits = [...new Set(this.referenceKits)];
    // inicio proceso
    for (let index = 0; index < this.referenceKits.length; index++) {
      const brick_kit = this.referenceKits[index];

      this.api.getDocument("bricks", brick_kit.reference).then(
        (data) => {
          brick_kit.brick = data;
          referenceKitsAux.push(brick_kit);
        },
        (err) => { }
      );
    }
    this.referenceKits = referenceKitsAux;
  }

  deleteReferenceKits(reference) {
    this.referenceKits = this.referenceKits.filter(
      (item) => item !== reference
    );
  }

  deleteReference(reference) {
    reference.description = unescape(encodeURIComponent(reference.description));
    reference.name = unescape(encodeURIComponent(reference.name));

    this.api.deleteDocument("recommended", reference.$key).then((data) => {
      this.getReferences();
      this.getReferencesMixology();
      this.http
        .get(
          `https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(
            JSON.stringify(reference)
          )}&role=${this.auth.role}`
        )
        .subscribe((response) => { });
    });
  }

  visibleChange(event): void {
    console.log("event", event);
    // if (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'HN') {
    if (this.auth.countryCode) {
      const isChecked = event.detail.checked;
      if (isChecked) {
        this.isToggleBtnChecked = false;
      } else {
        this.isToggleBtnChecked = true;
      }
    }
  }

  mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
    Swal.fire({
      position: "top-end",
      icon: icono,
      title: mensaje,
      showConfirmButton: false,
      timer: 3500,
      toast: true,
    });
  }

  // supplier changeSelect
  onChange(evt) {
    this.supplier = evt;
  }

  onChangeNivel(evt) {
    this.nivel = evt;
  }

  // funciones para manejo de detalle del brick developUX
  // ====================== Manejo de imagenes =========================
  cargarImagen(event: any, position: number) {
    const image: File = event.srcElement.files[0];

    if (!image.type.includes("image")) {
      this.mensajeAlerta("error", "Favor seleccionar un archivo de imagen");
      return;
    }

    const reader = new FileReader();

    if (position == 4) {
      reader.onloadend = () => {
        this.mainImage = reader.result as string;
        this.mainImageFile = image;
        this.listImage[0] = reader.result as string;
        this.listImageFile[0] = image;
      };
    } else {
      reader.onloadend = () => {
        this.listImage[position] = reader.result as string;
        this.listImageFile[position] = image;
      };
    }

    reader.readAsDataURL(image);
  }

  // =============================================================
  activarCaracteristicas() {
    if (this.wineisActive == false) {
      this.wineisActive = true;
      this.brickForm.addControl(
        "vino",
        this.formBuilder.group({
          ligero: ["", [Validators.required, Validators.max(5)]],
          dulce: ["", [Validators.required, Validators.max(5)]],
        })
      );
    } else {
      this.brickForm.removeControl("vino");
      this.wineisActive = false;
    }
  }

  // ===================== Validaciones de formulario ===================
  validarCampo(campo: string) {
    return (
      this.brickForm.get(campo).invalid && this.brickForm.get(campo).touched
    );
  }

  // ================== Permitir solo numeros ============================
  onlyNumbers(event: KeyboardEvent): boolean {
    const key = event.key;
    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

    if (numbers.includes(key)) return true;
    return false;
  }

  // ============== Permitir solo numeros con decimales ==================
  onlyNumbersDecimal(event: KeyboardEvent): boolean {
    const key = event.key;
    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."];

    if (numbers.includes(key)) return true;
    return false;
  }

  // =============================================================
  addValidationGroup(campo: string) {
    if (this.brickForm.get(campo).status == "DISABLED") {
      this.brickForm.get(campo).enable();
      this.brickForm.get(campo).setValidators([Validators.required]);
    } else {
      this.brickForm.get(campo).setValue("");
      this.brickForm.get(campo).clearValidators();
      this.brickForm.get(campo).disable();
    }
    this.brickForm.get(campo).updateValueAndValidity();
  }

  // =============================================================
  loadDataWine(product) {
    if (product.vino) {
      this.activarCaracteristicas();
      this.brickForm.get("activarVino").setValue(true);

      this.brickForm.get("vino").setValue({
        ligero: product.vino.ligero,
        dulce: product.vino.dulce,
      });
    }
  }
  // =============================================================
  // Carga de datos para grupos de formulario - Grupo detalles
  loadDetails(producto) {
    if (producto.detalles == undefined) return;

    const fieldsText = [
      "region",
      "cristaleria",
      "maridaje",
      "temperatura",
      "notasCatacion",
    ];

    Object.keys(producto.detalles).forEach((key) => {
      if (
        (fieldsText.includes(key) && producto.detalles[key]) ||
        producto.detalles[key] > 0
      ) {
        this.brickForm
          .get("detalles")
          .get(key)
          .setValue(producto.detalles[key]);
        this.brickForm.get("detalles").get(key).enable();
      }
    });
  }

  // =============================================================
  // Carga de imagenes
  loadImagesCarrousel(product) {
    this.mainImage =
      product.image != undefined ? product.image.downloadURL : "";

    if (product.imagen_carrusel) {
      // this.listImage[0] = product.imagen_carrusel.downloadURL[0] || '';
      this.listImage[0] =
        product.imagen_carrusel.downloadURL[0] != undefined &&
          product.imagen_carrusel.downloadURL[0] != ""
          ? product.imagen_carrusel.downloadURL[0]
          : product.image.downloadURL || "";
      if (
        product.imagen_carrusel.downloadURL[1] != undefined &&
        product.imagen_carrusel.downloadURL[1] != ""
      ) {
        this.listImage[1] = product.imagen_carrusel.downloadURL[1];
      }
      if (
        product.imagen_carrusel.downloadURL[2] != undefined &&
        product.imagen_carrusel.downloadURL[2] != ""
      ) {
        this.listImage[2] = product.imagen_carrusel.downloadURL[2];
      }
      // this.listImage[2] = product.imagen_carrusel.downloadURL[2] || '';
    } else {
      this.listImage[0] =
        product.image != undefined ? product.image.downloadURL : "";
    }
  }

  // =============================================================
  // eliminar imagen del carrusel
  deleteImageCarrousel(position: number) {
    this.SwalService.fire({
      title: "¿Está seguro de eliminar esta imagen?",
      text: "No podrá revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.listImage[position] = "";
        this.brick.imagen_carrusel.downloadURL[position] = "";

        this.api
          .updateDocument("bricks", this.brick.$key, {
            imagen_carrusel: {
              downloadURL: this.brick.imagen_carrusel.downloadURL,
            },
          })
          .then((data) => {
            this.mensajeAlerta("success", "Imagen eliminada correctamente");
          });
      }
    });
  }

  // ======================= Revisar validaciones =======================
  reviewValidations(): boolean {
    if (this.brickForm.invalid) {
      this.brickForm.markAllAsTouched();
      this.mensajeAlerta(
        "error",
        "Favor completar los campos marcados en rojo"
      );
      return false;
    }

    return true;
  }

  addSuperbPresentation() {
    this.api
      .addDocument(`bricks/${this.brick.$key}/superb_presentations`, {
        presentation: this.superb_PresentationName,
        price: 0,
        reward: 0,
        codeSap: "",
        bar_code: "",
      })
      .then((data) => {
        this.superb_PresentationName = "";
      });
  }

  addOnSuperbPresentation() {
    this.api
      .addDocument(`bricks/${this.brick.$key}/superb_on_presentations`, {
        presentation: this.superb_on_presentationName,
        price: 0,
        codeSap: "",
        bar_code: "",
      })
      .then((data) => {
        this.superb_on_presentationName = "";
      });
  }
  //superbPresentations
  //superb_on_presentations

  changeSuperbMainCheck(namePresentation, presentation, $event) {
    this.superbPresentations.forEach((element) => {
      if (element.presentation != namePresentation) {
        element.main = false;
      } else {
        // element.main = true;
      }
    });
  }

  changeOnSuperbMain(namePresentation, presentation, $event) {
    this.superb_on_presentations.forEach((element) => {
      if (element.presentation != namePresentation) {
        element.main = false;
      } else {
        // element.main = true;
      }
    });
  }

  removeSuperBPresentation(presentation) {
    this.SwalService.fire({
      title: "Estás seguro que deseas eliminar?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, estoy seguro",
    }).then((result) => {
      if (result.value) {
        this.api
          .deleteDocument(
            `bricks/${this.brick.$key}/superb_presentations/`,
            presentation.$key
          )
          .then((data) => {
            for (
              let index = 0;
              index < this.superbPresentations.length;
              index++
            ) {
              const element = this.superbPresentations[index];
              if (element.$key == presentation.$key) {
                this.superbPresentations.splice(index, 1);
              }
            }

            this.SwalService.fire(
              "Eliminado!",
              "La presentación Superb ha sido eliminada",
              "success"
            );
          });
      }
    });
  }

  removeSuperbOnPresentation(presentation) {
    this.SwalService.fire({
      title: "Estás seguro que deseas eliminar?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, estoy seguro",
    }).then((result) => {
      if (result.value) {
        this.api
          .deleteDocument(
            `bricks/${this.brick.$key}/superb_on_presentations/`,
            presentation.$key
          )
          .then((data) => {
            for (
              let index = 0;
              index < this.superb_on_presentations.length;
              index++
            ) {
              const element = this.superb_on_presentations[index];
              if (element.$key == presentation.$key) {
                this.superb_on_presentations.splice(index, 1);
              }
            }

            this.SwalService.fire(
              "Eliminado!",
              "La presentación Superb ON ha sido eliminada",
              "success"
            );
          });
      }
    });
  }

  changeSuperbVisible(presentation, $event) {
    const isChecked = $event.detail.checked;

    if (isChecked) {
      presentation.visible = true;
    } else {
      presentation.visible = false;
      presentation.inventory = 0;
    }
    this.changeVisibleOnTriger(this.superb_on_presentations, presentation);
  }

  copied(e) {
    this.mensajeAlerta("success", "Copiado");
    this._clipboardService.copyFromContent(this.clipboard);
  }

  // sincronizacion con SAP
  async changeSincCheck(presentation, type, $event) {
    if (!presentation.hasOwnProperty("codeSap") || presentation.codeSap == "") {
      this.mensajeAlerta("error", "Favor ingresar Codigo SYS");
      presentation.sincSAP = false;
      $event.detail.checked = this.statusChecked(presentation);
      return;
    }
    const isChecked = $event.detail.checked;

    if (isChecked) {
      if (presentation.codeSap != undefined) {
        let data = [presentation.codeSap];
        let priceList =
          type === "barlleno"
            ? this.auth.priceList
            : type === "barlleno-on"
              ? 8
              : 2;

        // Realiza la peticion
        let respSAP: any = await this.sap.getPostsInventoryAndPrice(
          data,
          priceList,
          this.auth.codeCellar
        );

        if (
          respSAP["data"].length > 0 &&
          respSAP["data"][0][presentation.codeSap]["price"] > 0
        ) {
          let element = respSAP["data"][0];

          if (
            presentation.codeSap in element &&
            element[presentation.codeSap].price > 0
          ) {
            presentation.inventory = element[presentation.codeSap].inventory;
            presentation.price = element[presentation.codeSap].price;

            if (element[presentation.codeSap].inventory == 0) {
              presentation.visible = false;
            }
          }
          presentation.sincSAP = true;
          this.mensajeAlerta("success", "Precio e inventario cambiado");
        } else {
          this.mensajeAlerta(
            "error",
            "Codigio no valido o no tiene precio el articulo"
          );
          presentation.sincSAP = false;
          for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.$key == presentation.$key) {
              this.presentations.sincSAP = false;
            }
          }
        }
      } else {
        presentation.sincSAP = false;
      }
    } else {
      presentation.sincSAP = false;
    }

    // Valida la cantidad de todas las presentaciones
    this.validateInventoryAndVisibility();
  }
  // Metodo para sincronizar todas las presentaciones
  async updateAllPresentation() {
    let codeSAP = [];

    // Recorre las presentaciones e obtiene el codigo de sap de cada una
    for (let i = 0; i < this.presentations.length; i++) {
      if (
        this.presentations[i].hasOwnProperty("codeSap") ||
        this.presentations[i].codeSap != ""
      ) {
        codeSAP.push(this.presentations[i].codeSap);
      }
    }
    let respSAP: any = await this.sap.getPostsInventoryAndPrice(
      codeSAP,
      this.auth.priceList,
      this.auth.codeCellar
    );

    if (respSAP["data"] == undefined) {
      return;
    }

    for (let i = 0; i < this.presentations.length; i++) {
      for (let j = 0; j < respSAP["data"].length; j++) {
        let element = respSAP["data"][j];

        if (element[this.presentations[i].codeSap] !== undefined) {
          this.presentations[i].price =
            element[this.presentations[i].codeSap].price;
          this.presentations[i].inventory =
            element[this.presentations[i].codeSap].inventory;
          // this.presentations[i].ap.price = element[this.presentations[i].codeSap].price
          this.presentations[i].sincSAP = true;
          if (element[this.presentations[i].codeSap].inventory == 0) {
            this.presentations[i].visible = false;
          }
          if (
            element[this.presentations[i].codeSap].inventory > 0 &&
            element[this.presentations[i].codeSap].price > 0
          ) {
            this.presentations[i].visible = true;
          }
        }
      }
    }
    this.validateInventoryAndVisibility();
  }

  statusChecked(presentation) {
    return (
      presentation.hasOwnProperty("codeSap") &&
      presentation.codeSap != "" &&
      presentation.hasOwnProperty("sincSAP") &&
      presentation.sincSAP == true
    );
  }

  // Metodo para ocultar el brick
  validateInventoryAndVisibility() {
    let countVisible = 0;
    if (this.presentations.length > 0) {
      for (let i = 0; i < this.presentations.length; i++) {
        if (
          this.presentations[i].hasOwnProperty("inventory") &&
          this.presentations[i].inventory == 0
        ) {
          countVisible++;
        }
      }
    }

    if (countVisible == this.presentations.length) {
      this.brick.visible = false;
      this.brickForm.controls["visible"].setValue(false);
    } else {
      this.brick.visible = true;
      this.brickForm.controls["visible"].setValue(true);
    }
  }

  async openMixers(presentation) {
    console.log("open mixers");
    console.log(presentation);

    const modal = await this.modalController.create({
      component: AddMixersComponent,
      cssClass: "addModal",
      componentProps: {
        presentation: presentation,
      }
    });
    return await modal.present();
  }

  async validateVisibilityPresentation(presentation) {
    console.log(presentation);
    if (presentation.visible) {
      return true;
    } else {
      return false;
    }
  }
}
