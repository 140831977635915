export const RoleFiveOther = [
    {
        title: 'Monitor V2',
        url: '/index/monitor-v2',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Monitor Solicitudes',
        url: '/index/monitor-requests-orders',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Eventos',
        url: '/index/events',
        base: '',
        icon: 'calendar-outline',
        alert: false
    },
    {
        title: 'Reportes',
        url: '/index/reports-v3',
        base: '',
        // url: this.auth.countryCode === 'DO'  ?  '/reports-v2'  :  '/reports-v3',
        icon: 'newspaper-outline',
        alert: false
    },
    {
        title: 'Asistencia Técnica',
        url: '/index/support',
        base: '',
        icon: 'build-outline',
        alert: false
    }
]