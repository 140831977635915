import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'way'
})
export class WayPipe implements PipeTransform {

  transform(icono: string, estado: string): string {

    if(estado == 'way' || estado == 'delivered'){
      return `../../assets/icon/Iconos/on-way.svg`
    }else{
      return `../../assets/icon/Iconos/${icono}.svg`
    }

  }

}
