export const Base = [
    {
        title: 'Monitor V2',
        url: '/index/monitor-v2',
        base: '/index/monitor-v2',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Dashboard',
        url: '/index/section/dashboard',
        base: '/index/section/dashboard',
        icon: 'home',
        alert: false
    },
    {
        title: 'Buscador Producto',
        url: '/index/search-brick',
        base: '/index/search-brick',
        icon: 'search-outline',
        alert: false
    },
    {
        title: 'Vinos',
        url: '/index/section/wines',
        base: '',
        icon: 'icon-wine',
        customIcon: true,
        alert: false
    },
    {
        title: 'Destilados',
        url: '/index/section/liquors',
        base: '',
        icon: 'icon-liquor',
        customIcon: true,
        alert: false
    },
    {
        title: 'Cervezas',
        url: '/index/section/beers',
        base: '',
        icon: 'icon-beer',
        customIcon: true,
        alert: false
    },
    {
        title: 'No alcoholicas',
        url: '/index/section/non-alcoholic',
        base: '',
        icon: 'icon-non-alcoholic',
        customIcon: true,
        alert: false
    },
    {
        title: 'Extras',
        url: '/index/section/extras',
        base: '',
        icon: 'icon-snack',
        customIcon: true,
        alert: false
    },
    {
        title: 'Mixology',
        url: '/index/explore-section',
        base: '',
        icon: 'apps',
        alert: false
    },
    {
        title: 'Kits',
        url: '/index/section/kits',
        base: '',
        icon: 'file-tray-stacked',
        alert: false
    },
    {
        title: 'Para comer',
        url: '/index/section/toeat',
        base: '',
        icon: 'icon-toeat',
        customIcon: true,
        alert: false
    },
    {
        title: 'Licores y Digestivos',
        url: '/index/section/digestic',
        base: '',
        icon: 'icon-digestic',
        customIcon: true,
        alert: false
    },
    {
        title: 'Club Barlleno',
        url: '/index/section/club',
        base: '',
        icon: 'basket',
        alert: false
    },
    {
        title: 'Chats',
        url: '/index/chats',
        base: '',
        icon: 'chatbubbles',
        alert: false
    },
    {
        title: 'Chats pendientes',
        url: '/index/chats-pendientes',
        base: '',
        icon: 'chatbubbles',
        alert: false
    },
    {
        title: 'Solicitudes de verificación',
        url: '/index/verified-requests',
        base: '',
        icon: 'archive-outline',
        alert: false
    },
    {
        title: 'Socios',
        url: '/index/verified',
        base: '',
        icon: 'star',
        alert: false
    },
    {
        title: 'Proveedores',
        url: '/index/suppliers',
        base: '',
        icon: 'git-branch-outline',
        alert: false
    },
    {
        title: 'Delivery',
        url: '/index/delivery',
        base: '',
        icon: 'bicycle-outline',
        alert: false
    },
    {
        title: 'Cumpleañeros del Mes',
        url: '/index/beerday',
        base: '',
        customIcon: true,
        icon: 'icon-celebration',
        alert: false
    },
    {
        title: 'Notificaciones Push',
        url: '/index/push',
        base: '',
        icon: 'notifications-circle-outline',
        alert: false
    },
    {
        title: 'Cuentas',
        url: '/index/accounts',
        base: '',
        icon: 'people',
        alert: false
    },
    {
        title: 'Solicitudes de cuentas de negocios',
        url: '/index/business-accounts',
        base: '',
        icon: 'business-outline',
        alert: false
    },
    {
        title: 'Cuentas de negocios Verificadas',
        url: '/index/business-accounts-validate',
        base: '',
        customIcon: true,
        icon: 'icon-profits',
        alert: false
    },
    {
        title: 'Reportes',
        url: '/index/reports-v3',
        base: '',
        // url: this.auth.countryCode === 'DO'  ?  '/reports-v2'  :  '/reports-v3',
        icon: 'newspaper-outline',
        alert: false
    },
    {
        title: 'Cupones',
        url: '/index/coupons',
        base: '',
        icon: 'pricetags-outline',
        alert: false
    },
    // {
    //     title: 'Evaluaciones',
    //     url: '/evaluaciones',
    // base: '',
    //     icon: 'happy-outline',
    //     alert: false
    // },
    // {
    //     title: 'Eventos',
    //     url: '/events',
    // base: '',
    //     icon: 'calendar-outline',
    //     alert: false
    // },
    {
        title: 'Configuraciones',
        url: '/index/settings',
        base: '',
        icon: 'cog',
        alert: false
    },
    {
        title: 'Asistencia Técnica',
        url: '/index/support',
        base: '',
        icon: 'build-outline',
        alert: false
    }
];