import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delivered'
})
export class DeliveredPipe implements PipeTransform {

  transform(icono: string, estado: string): string {
    if(estado == 'delivered'){
      return `../../assets/icon/Iconos/entregado.svg`;
    }else{
      return `../../assets/icon/Iconos/${icono}.svg`
    }
  }

}
