import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-inactive-accounts',
  templateUrl: './table-inactive-accounts.component.html',
  styleUrls: ['./table-inactive-accounts.component.scss'],
})
export class TableInactiveAccountsComponent implements OnInit {
	@Input() accounts:  any

  constructor() { }

  ngOnInit() {}

}
