export const RoleTenSV = [
    {
        title: 'Monitor V5',
        url: '/index/monitor-v5',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Eventos',
        url: '/index/events',
        base: '',
        icon: 'calendar-outline',
        alert: false
    },
    {
        title: 'Reportes Evento',
        url: '/index/reports-event',
        base: '',
        icon: 'newspaper-outline',
        alert: false
    },
    {
        title: 'Asistencia Técnica',
        url: '/index/support',
        base: '',
        icon: 'build-outline',
        alert: false
    }
]