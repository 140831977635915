import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { of } from 'rxjs';

import { ClipboardService } from 'ngx-clipboard'
import Swal, { SweetAlertIcon } from 'sweetalert2';



@Component({
    selector: 'app-brick-preview',
    templateUrl: './brick-preview.component.html',
    styleUrls: ['./brick-preview.component.scss'],
})
export class BrickPreviewComponent implements OnInit {

    brickForm: FormGroup;
    brick_key: any;
    brick: any;
    brick_image: any = false;
    presentationName: any;
    presentations: any = [];
    category: any;
    type: any = 'brick';
    recommended: any = [];
    clipboard: any = ''
    isCopied1: boolean;

    tinyConfs: any = {
        height: 500,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        importcss_append: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'sliding',
        contextmenu: "link table",
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        ],
    }


    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        private _clipboardService: ClipboardService

    ) {
        this.brick = navParams.get('brick');
        if (navParams.get('type')) {
            this.type = navParams.get('type');
        }

        if (this.brick) {
            this.clipboard = this.brick.image.downloadURL;
        }


        this.brickForm = this.formBuilder.group({
            name: [this.brick.name, [Validators.required]],
            description: [this.brick.description, [Validators.required]],
            visible: [this.brick.visible, [Validators.required]],
            type: ['brick', [Validators.required]],
            position: [this.brick.position],
            supplier: [this.brick.supplier],
            bar_code: [this.brick.bar_code],
        });

        if (this.type == 'brick') {
            this.api.getRef(`bricks/${this.brick.$key}/presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.presentations.push(presentation);
                        }
                    });
                })
        }
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

    copied(e) {
        // console.log("event ", e)
        // console.log(this.clipboard);
        this.mensajeAlerta('success', 'Copiado');
        this._clipboardService.copyFromContent(this.clipboard)
    }
    dismiss() {
        this.modalController.dismiss();
    }

    ngOnInit() { }



}
