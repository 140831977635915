import { DataOrderClient } from './../../interfaces/orderPoints';
import { EncuentasServiceService } from './../../services/reports/encuentas-service.service';
import { DatesReports } from './../../interfaces/DatesReports.interface';
import { TransfTimeOrdersService } from './../../services/transf-data/transf-time-orders.service';
import { Component, OnInit } from '@angular/core';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-reporte-encuestas',
  templateUrl: './reporte-encuestas.component.html',
  styleUrls: ['./reporte-encuestas.component.scss'],
})
export class ReporteEncuestasComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private transferData: TransfTimeOrdersService,
    private serviceDataEncuesta: EncuentasServiceService
  ) { }

  ngOnInit() {
    this.getDatesEncuentas();
  }

  // fechasEncuentas : DatesReports;
  dataEncusta     : DataOrderClient[] = [];
  isLoadingData   : boolean = false;

  getDatesEncuentas(){
    this.transferData.fechasEncuentas.subscribe(
      (response: DatesReports) => {
        //console.log('Escuche algo')
        this.getReportEncuestas(response.startDate, response.endDate)
      }
    )
  }

  getReportEncuestas(startDate: Date, endDate: Date){
    this.isLoadingData = true;
    this.serviceDataEncuesta.getReporteEncuestas(startDate, endDate).then(
      response => {
        this.dataEncusta = response;
        this.isLoadingData = false;
      }
    )
  }

  getReportOrdersByTime() {
    const reportOrdersByTime = [];

    this.dataEncusta.forEach(
      order => {
        reportOrdersByTime.push({
          "No. Order": order.dataOrder.number,
          "Cliente": `${order.client.name} ${order.client.lastname}`,
          "Fecha registro": order.dataOrder.added_date,
          "Monto": order.dataOrder.total,
          "Teléfono": order.dataOrder.phone,
          "Puntuación App": order.dataOrder.appExperience,
          "Puntuación servicio": order.dataOrder.serviceExperience
        });
      }
    );

    var options = {
      title: 'Reporte puntuación de servicio y app',
      headers: [
        "No. Order",
        "Cliente",
        "Fecha registro",
        "Monto",
        "Teléfono",
        "Puntuación App",
        "Puntuación servicio"
      ]
    };

    new Angular5Csv(
      reportOrdersByTime,
      `Reporte puntuación ${this.auth.countryCode} ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
      options
    );
  }



}
