import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconosTarjetas'
})
export class IconosTarjetasPipe implements PipeTransform {

  transform(nombre: string, ...args: any[]): string {
    return `../../assets/icon/Iconos/${nombre}.svg`;
  }

}
