import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { versionApp } from 'src/environments/environment.prod';

// Contenido del menu
import { Base } from 'src/app/data/Menu/Base';

import { RoleZeroDO } from 'src/app/data/Menu/DO/RoleZeroDO';
import { RoleOneDO } from 'src/app/data/Menu/DO/RoleOneDO';
import { RoleTwoDO } from 'src/app/data/Menu/DO/RoleTwoDO';
import { RoleThreeDO } from 'src/app/data/Menu/DO/RoleThreeDO';
import { RoleFourthDO } from 'src/app/data/Menu/DO/RoleFourthDO';
import { RoleFiveDO } from 'src/app/data/Menu/DO/RoleFiveDO';

import { RoleZeroSV } from 'src/app/data/Menu/SV/RoleZeroSV';
import { RoleOneSV } from 'src/app/data/Menu/SV/RoleOneSV';
import { RoleTwoSV } from 'src/app/data/Menu/SV/RoleTwoSV';
import { RoleThreeSV } from 'src/app/data/Menu/SV/RoleThreeSV';
import { RoleFourthSV } from 'src/app/data/Menu/SV/RoleFourtSV';
import { RoleSixSV } from 'src/app/data/Menu/SV/RoleSixSV';
import { RoleSevenSV } from 'src/app/data/Menu/SV/RoleSevenSV';
import { RoleEightSV } from 'src/app/data/Menu/SV/RoleEightSV';
import { RoleNineSV } from 'src/app/data/Menu/SV/RoleNineSV';
import { RoleTenSV } from 'src/app/data/Menu/SV/RoleTenSV';

import { RoleZeroOther } from 'src/app/data/Menu/Other/RoleZeroOther';
import { RoleOneOther } from 'src/app/data/Menu/Other/RoleOneOther';
import { RoleTwoOther } from 'src/app/data/Menu/Other/RoleTwoOther';
import { RoleThreeOther } from 'src/app/data/Menu/Other/RoleThreeOther';
import { RoleFourthOther } from 'src/app/data/Menu/Other/RoleFourtOther';
import { RoleFiveOther } from 'src/app/data/Menu/Other/RoleFiveOther';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

    @Input('page') active_page: any;
    @Input('changeSize') callbackFunction: (args: any) => void;

    @Input('hiden') hiden:Boolean;
    hiddenMenu: Boolean = true

    version = versionApp

    public appPages = Base


    constructor(
        public auth: AuthService,
    ) {
        if (this.auth.countryCode === 'DO') {
            // Rol 0 - Soporte ROOT
            if (this.auth.role == "0") {
                this.appPages = RoleZeroDO
                // Rol 1 - Contacto
            } else if (this.auth.role == "1") {
                this.appPages = RoleOneDO
                // Rol 2 - Marketing
            } else if (this.auth.role == "2") {
                this.appPages = RoleTwoDO
                // Rol 3 - Tienda
            } else if (this.auth.role == "3") {
                this.appPages = RoleThreeDO
            } else if (this.auth.role == "4") {
                this.appPages = RoleFourthDO
            } else if (this.auth.role == "5") {
                this.appPages = RoleFiveDO
            } else {
                this.appPages = []
            }
        } else if (this.auth.countryCode === 'SV' || this.auth.countryCode === 'HN' || this.auth.countryCode ==='RAF') {
            // CountryCode !== DO
            // Rol 0 - Soporte ROOT
            if (this.auth.role == "0") {
                this.appPages = RoleZeroSV
                // Rol 1 - Contacto
            } else if (this.auth.role == "1") {
                this.appPages = RoleOneSV
                // Rol 2 - Marketing
            } else if (this.auth.role == "2") {
                this.appPages = RoleTwoSV
                // Rol 3 - Tienda
            } else if (this.auth.role == "3") {
                this.appPages = RoleThreeSV
            } else if (this.auth.role == "4") {
                this.appPages = RoleFourthSV
            } else if (this.auth.role == "6") {
                // perfil de eventos barlleno (el dia del evento en sitio)
                this.appPages = RoleSixSV
            } else if (this.auth.role == "7") {
                // perfil de superB
                this.appPages = RoleSevenSV
            } else if (this.auth.role == "8") {
                // perfil admin events barlleno incluye reportes de barlleno
                this.appPages = RoleEightSV
            } else if (this.auth.role == "9") {
                this.appPages = RoleNineSV
            } else if (this.auth.role == "10") {
                this.appPages = RoleTenSV
            } else {
                this.appPages = [ ]
            }
        } else {
            // CountryCode !== DO || != SV
            // Rol 0 - Soporte ROOT
            if (this.auth.role == "0") {
                this.appPages = RoleZeroOther
                // Rol 1 - Contacto
            } else if (this.auth.role == "1") {
                this.appPages = RoleOneOther
                // Rol 2 - Marketing
            } else if (this.auth.role == "2") {
                this.appPages = RoleTwoOther
                // Rol 3 - Tienda
            } else if (this.auth.role == "3") {
                this.appPages = RoleThreeOther
            } else if (this.auth.role == "4") {
                this.appPages = RoleFourthOther
            } else if (this.auth.role == "5") {
                this.appPages = RoleFiveOther
            } else {
                this.appPages = [ ]
            }
        }

    }

    ngOnInit() {
        let root = document.getElementById("menu")
        root.style.transition ='width 0.10s';
     }

     changeVisibility(){
        this.hiddenMenu = !this.hiddenMenu
        let root = document.getElementById("menu")
        this.callbackFunction(root)
    }
    

    validateSegment (title:string): boolean {
        let url = window.location.href.split("#")
        return url[1] == title
    }
}
