import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'store'
})
export class StorePipe implements PipeTransform {

  transform(icono: string, estado: string): string {

    // //console.log(estado);
    if(estado == 'delivered' || estado == 'way'){
      return `../../assets/icon/Iconos/tienda-ok.svg`;
    }else{
      return `../../assets/icon/Iconos/tienda-pend.svg`;
    }
  }

}