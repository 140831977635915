import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { OrdersService } from 'src/app/services/database/orders.service';
import { SapService } from 'src/app/services/sap/sap.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-new-order',
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent implements OnInit {
    products: any = []
    user: any
    currency
    event
    countryCode
    categoryEvent
    storeCode
    saveAndReload
    storeKey
    details:any = {
        total: 0,
        subTotal: 0,
        tip: 0,
        totalPaymentCash: 0,
        remaing: 0
    }
    typePayment= "cash"
    constructor(
        public navParams: NavParams,
        public loadingCtrl: LoadingController,
        public sapS: SapService,
        public modalController: ModalController,
        public orderService: OrdersService
    ) { }

    ngOnInit() { 
        this.products = this.navParams.get("products")
        this.currency = this.navParams.get("currency")
        this.user = this.navParams.get("user")
        this.countryCode = this.navParams.get("countryCode")
        this.categoryEvent = this.navParams.get("categoryEvent")
        this.event = this.navParams.get("event")
        this.storeCode = this.navParams.get("storeCode")
        this.storeKey = this.navParams.get("storeKey")
        this.saveAndReload = this.navParams.get("saveAndReload")
        this.calculateDetails()
    }

    calculateDetails ( ) {
        for ( let i =0; i< this.products.length; i++ ) {
            this.details.subTotal += Number(this.products[i].presentation.quantity) * Number(this.products[i].presentation.price)
        }
        this.details.total = this.details.subTotal + this.details.tip
    }

    calculateRemaing () {
        this.details.total = Number(this.details.subTotal) + Number(this.details.tip)
        if ( this.details.totalPaymentCash > 0 ) {
            this.details.remaing =  Number(this.details.totalPaymentCash) - Number(this.details.total)
        }
    }

    changeSegment(segment) {
        this.typePayment = segment
    }

    saveNewOrder () {
        
        this.loader().then(loader => {
            loader.present().then(async()=>{
                let order = {
                    account : this.user,
                    added_date: new Date(),
                    address: false,
                    applied_event_key: true, 
                    bricks: [],
                    cashChange: false, 
                    change: this.details.totalPaymentCash > 0 ? this.details.totalPaymentCash : this.details.total,
                    countryCode: this.countryCode,
                    coupon: "",
                    direccionNit: "",
                    donation: 0,
                    dui: "000000-0",
                    event_brick_table: null,
                    event_category_key: this.categoryEvent,
                    event_code_table: null,
                    event_key: this.event,
                    event_order: true,
                    event_row_code: null,
                    event_seats_code: null,
                    event_user_select_code: null,
                    gift_price: false,
                    nit: "",
                    note: "",
                    number: 0,
                    payment: {
                        type: this.typePayment
                    },
                    phone: "7777-7777",
                    pickup: true,
                    point: 0,
                    ponts_add_applied: true, 
                    qr_reader: false,
                    razonSocial: "",
                    schedule_date: new Date(),
                    schedule_time: new Date(),
                    scheduled: false,
                    second_round_code: "",
                    second_round_discount: "",
                    status:"pending",
                    status_time: {
                        pending: new Date()
                    },
                    storeCode: this.storeCode,
                    storeKey: this.storeKey,
                    supplier_discount: [],
                    supplier_discount_not_applied: 0,
                    supplier_discount_total: 0,
                    sytemOs: "web",
                    tip: this.details.tip,
                    total: this.details.subTotal,
                    total_delivery: 0,
                    total_points_partner: 0,
                    usedCoupons: []
                }
            

                this.products.forEach((element, i) => {
                    order.bricks.push({
                        ...element.presentation,
                        brick_key: element.$key,
                        brick: element,
                        subtotal: Number(element.presentation.price) * Number(element.presentation.quantity)
                    })
                    delete order.bricks[i].presentation
                });

                
                let headers = new HttpHeaders()
                this.sapS.post("/service/orders/create/neworder", {token: this.user ,data:order}, headers).subscribe({
                    next: (response) => {
                        this.orderService.getProductsEvents()
                        this.modalController.dismiss();
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Orden guardada correctamente',
                            showConfirmButton: false,
                            timer: 3500,
                            toast: true
                        })
                        loader.dismiss();

                    }, error: (error) => {
                        console.log(error)
                        console.log(error.response)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Ocurrio un error al guardar la orden',
                            showConfirmButton: false,
                            timer: 3500,
                            toast: true
                        })
                        loader.dismiss();
                    }
                })
                
            })
        })
    }

    formatValue(e, type) {
        this.details[type] = Number(e.target.value)
        this.calculateRemaing()
    }
    
    
    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

}
