import { Component, Input, OnInit } from '@angular/core';
import { FunctionsService } from 'src/app/services/functions/functions.service';

@Component({
  selector: 'app-table-suppliers',
  templateUrl: './table-suppliers.component.html',
  styleUrls: ['./table-suppliers.component.scss'],
})
export class TableSuppliersComponent implements OnInit {
	searchBrick = "";
	searchSupplier = ""
	searchDate = ""
	bricks: any;
	@Input() bricksAux: any = [];
	@Input() currency: any = "";
  constructor(private functionsService: FunctionsService) { }

	ngOnInit() {
		this.bricks = this.bricksAux;
	}

	filterProducts( value: string, name: string ) {
		this.bricksAux = this.functionsService.filterArray(
			value,
			this.bricks,
			[name]
		);
	}

}
