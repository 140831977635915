import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'email'
})
export class EmialPipe implements PipeTransform {

  transform(icono: string, estado: string): string {

    // //console.log(estado);
    if(estado == 'email'){
      return `../../assets/icon/Iconos/email-ok.svg`;
    }else{
      return `../../assets/icon/Iconos/email-pend.svg`;
    }
  }

}