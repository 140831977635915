import { SapService } from 'src/app/services/sap/sap.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AngularFireFunctions } from 'angularfire2/functions';
import { SwalService } from 'src/app/services/alert/swal.service';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class FunctionsService {

	constructor(
		public api: ApiService,
		private fireFunctions: AngularFireFunctions,
		public loadingCtrl: LoadingController,
		public auth: AuthService,
		private http: HttpClient,
		public SwalService: SwalService,
		public sapService: SapService
	) { }

	filterArray(query, orgArray, searchParams) {
		if (!query) {
			return Array.from(orgArray)
		} else {
			let results: any[] = []

			orgArray.forEach((d: any) => {
				for (let i = 0; i < searchParams.length; i++) {
					let param = searchParams[i]
					if (typeof param == "object") {
						if (d[param.p][param.sb] != undefined && String(d[param.p][param.sb]).toLowerCase().includes(query.toLowerCase())) {
							results.push(d)
							break
						}
					} else {
						if (d[param] != undefined && String(d[param]).toLowerCase().includes(query.toLowerCase())) {
							results.push(d)
							break
						}
					}
				}
			})

			return results
		}
	}

	sendEmail(id: string): Promise<void> {
		const sendOrder = this.fireFunctions.httpsCallable('sendOrder')
		return new Promise((resolve, reject) => {
			sendOrder({ id: id })
				.toPromise()
				.then(() => resolve())
				.catch((err) => {
                    if (err.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(err);
                        reject(err);
                    }
                })
		});
	}

	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

	async updateStatustime(order, status, statusOrder) {
		// Update status_time track time
		await this.api.getRef('orders').ref
			.where('number', '==', order.number)
			.get()
			.then(snapshots => {
				// this.total_orders = snapshots.size;
				snapshots.forEach(async element => {
					let order = element.data();
					order.date = order.added_date.toDate();
					order.$key = element.id;

					if (order.hasOwnProperty('status_time')) {
						let status_time = order.status_time
						status_time[status] = new Date()
						let obj = {}
						if (status == 'delivered') {
							obj = {
								'status_time': status_time,
								'status': statusOrder,
								'account_waiter': this.auth.token,
							}
						} else {
							obj = {
								'status_time': status_time,
								'status': statusOrder,
							}
						}
						await this.api.updateDocument('orders', order.$key, obj)
					}
				});
			})
	}

	async notificacionMSG(order, message) {
		// console.log("init notificacionMSG")
		let toDay = new Date()
		// console.log("toDay = ", toDay)
		let fechaOrden = order.added_date;
		// console.log("fechaOrden = ", fechaOrden)
		const startTime: any = new Date(fechaOrden);
		// console.log("startTime = ", startTime)
		const endTime: any = new Date(toDay);
		// console.log("endTime = ", endTime)
		const diff = endTime - startTime;
		const horastemporal: number = Math.floor((diff % 86400000) / 3600000);
		// console.log("horastemporal = ", horastemporal)
		// return
		// agrego validacion de tiempo no mayor de 1 hora
		if (horastemporal > 0) {

		} else {
			// Notificacion al cliente segun la orden
			await this.api.getAllDocuments(`accounts/${order.account.$key}/push_keys`).then(async keys => {
				if (keys) {
					let push_keys = [];
					keys.forEach(element => {
						push_keys.push(element.push_key);
					});
					await this.api.sendPush(`Actualización de estado`, message, push_keys);
				}
			})
		}
		// mesaje de confirmacion de Cambio de estado
		this.SwalService.fire(
			'Estado actualizado!',
			'Tu estado ha sido actualizado correctamente',
			'success'
		)
	}

	changeStatus(order, status, gift = false) {
		return new Promise((resolve, reject) => {
			// console.log("changeStatus init")
			this.SwalService.fire({
				title: 'Estás seguro que deseas cambiar el estado?',
				text: "¡No podrás revertir esto!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí, estoy seguro'
			}).then(async (result) => {
				if (result.value) {
					this.loader().then(loader => {
						loader.present().then(async () => {
							let statusOrder = ''
							if (status == 'pickup') {
								statusOrder = 'way'
							} else {
								statusOrder = status;
							}
							let message;
							if (status == 'making') {
								message = `Tu orden #${order.number} se está preparando 🔥`
							} else if (status == 'way') {
								message = `Tu orden #${order.number} ya va de camino 🚚`
							} else if (status == 'delivered') {
								message = `Tu orden #${order.number} ha sido entregada 🏡`
							} else if (status == 'pickup') {
								message = `Tu orden #${order.number} está lista para pasar por ella 🏪`
							}
							if (status == 'pickup') {
								order.status = 'way'
							} else {
								order.status = status;
								let body = {
									token: this.auth.token,
									data: {
										numberOrder: order.number,
										type: "",
										message:""
									}

								}
								let headers = new HttpHeaders({
									'Access-Control-Allow-Origin': 'origin',
									'application': 'x-www-form-urlencoded',
									'Content-type': 'application/json',
									'Access-Control-Allow-Credentials': 'true',
									'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
									'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
								}) 
								this.sapService.post("/service/orders/update/delivery/assignate", body, headers).subscribe({
								//this.sapService.post("https://service-dev.barlleno.app/service/orders/update/delivery/assignate", body, headers, true).subscribe({
									next: (response) => {
										console.log(response)
									},
									error: (error) =>{ 
										console.log(error)
									}
								})
							}
							if (gift) {
								message = message + `+ 2 🍺 Cervezas Gratis!!.`
							}
							//	// console.log("aqui paso 1")
							if (status == "delivered" && !order.hasOwnProperty('points_add_applied')) {
								// this.checkVisibleBrick(order)
								// const respUpdate = await this.checkVisibleBrick(order)
								//	// console.log("respUpdate = ", respUpdate)

								// aqui debe ir la evaluacion de la visibilidad de los bricks
								// para efectos de desarrollo
								// const respCheckBrickVisible = await this.checkBrickVisible(order)
								//	// console.log("respCheckBrickVisible", respCheckBrickVisible)

								// if (order.payment.type == 'card') {
								// 	// let respSetDefaultCard = await this.setDefaultCard(order)
								// 	//	// console.log("respSetDefaultCard = ", respSetDefaultCard)
								// }

								//	// console.log("ACTUALZIAR PUNTOS BARLLENO / ESTRELLAS PARTNER")
								// let recalculoTotales = await this.validarTotales(order)
								//	// console.log("recalculoTotales", recalculoTotales, "recalculoTotales", typeof recalculoTotales)

								// event_key que no exista flag de eventos barlleno para asignar puntos
								if (!order.hasOwnProperty('event_key')) {
								} else {
									// si existe flag event_key solo procesar orden
									await this.api.updateDocument('orders', order.$key, {
										'points_add_applied': true,
										'applied_event_key': true
									})

									await this.updateStatustime(order, status, statusOrder)

									await this.notificacionMSG(order, message)
									// al finalizar el cambio de estado quita la orden de pantalla
									if (status == "delivered") {
										// await new Promise(r => setTimeout(r, 2000));
										// this.orders = this.orders.filter(element => element.number !== order.number)
										loader.dismiss();
										resolve(true)
									}
								}
							}
							else if (status == "making" || status == "way" || status == "pickup") {
								//	// console.log("aqui paso 2")
								await this.updateStatustime(order, status, statusOrder)
								await this.notificacionMSG(order, message)
								loader.dismiss();
								resolve(true)
							}
							loader.dismiss();
							resolve(true)
						})
					})
				}
			})
		});
	}

	capture(order) {
		return new Promise((resolve, reject) => {
			this.loader().then(loader => {
				loader.present().then(() => {
					this.SwalService.fire({
						title: 'Esta seguro que deseas Aceptar la transferencia?',
						text: "¡No podrás revertir esto!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Si, estoy seguro'
					}).then((result) => {

						if (result.value) {
							let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
							let transaction_id = order.transaction_id;
							let azulId = order.number;
							let url;
							if (!order.hasOwnProperty('TransactionIdentifier') && this.auth.countryCode == 'SV') {
								// FAC 3DSv1
								url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

							} else if (order.hasOwnProperty('TransactionIdentifier') && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT')) {
								//  console.log("ingreso a FAC")
								// nuevo FAC 3DSv2 
								const httpOptions = {
									headers: new HttpHeaders({
										'Access-Control-Allow-Origin': 'origin',
										'application': 'x-www-form-urlencoded',
										'Content-type': 'application/json',
										'Access-Control-Allow-Credentials': 'true',
										'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
										'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
									})
								};

								let body = {
									"TransactionIdentifier": order.TransactionIdentifier,
									"TotalAmount": Number(order.total),
									"ExternalIdentifier": `${order.number}`
								}
								//  console.log("transaccion body = ", body, typeof body)

								url = `https://payments.paggi.app/powerTranz/${this.auth.countryCode}/Capture.php`;
								// url = `https://payments.paggi.app/powerTranz/SV/Capture.php`;

								this.http.post(url, JSON.stringify(body), httpOptions)
									.subscribe((response) => {
										//  console.log("POST -> Capture.php response = ", JSON.stringify(response))
										if (response['IsoResponseCode'] == '00') {
											this.api.updateDocument('orders', order.$key, {
												'transaction_status': 'captured',
												'status': 'making'
											}).then(data => {
												order.transaction_status = 'captured';
												this.SwalService.fire(
													'Transacción Capturada',
													'Captura de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												`No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno ${response['ResponseMessage']}`,
												'error'
											)
										}
										loader.dismiss();
										resolve(true)
									}, err => {
										loader.dismiss();
										resolve(true)
									});
							} else if (this.auth.countryCode == 'GT') {
								url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
							} else if (this.auth.countryCode == 'HN') {
								this.api.updateDocument('orders', order.$key, {
									'transaction_status': 'captured',
									'status': 'making'
								}).then(data => {
									order.transaction_status = 'captured';
									this.SwalService.fire(
										'Transacción Capturada',
										'Captura de fondos realizar con exito',
										'success'
									)
								})
								loader.dismiss();
								resolve(true)
							} else if (this.auth.countryCode == 'CO') {
								const httpOptions = {
									headers: new HttpHeaders({
										'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
									})
								};
								url = ((`https://production.wompi.co/v1/transactions/${transaction_id}`));
							}
							// agregando captura metodo de pago DO
							else if (this.auth.countryCode == 'DO') {
								url = ((`https://paymentsdr.barlleno.app/src/Azul/verifySale.php?${azulId}`));
								// console.log("--->Enviamos al endpoint");
								// console.log(azulId);
							}

							if (!order.hasOwnProperty('TransactionIdentifier') && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT')) {
								this.http.get(url)
									.subscribe((response) => {
										if (response['TransactionModificationResult']['ResponseCode'] == 1) {
											this.api.updateDocument('orders', order.$key, {
												'transaction_status': 'captured',
												'status': 'making'
											}).then(data => {
												order.transaction_status = 'captured';
												this.SwalService.fire(
													'Transacción Capturada',
													'Captura de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
												'error'
											)
										}
										loader.dismiss();
										resolve(true)
									}, err => {
										loader.dismiss();
										resolve(true)
									});
							}
							else if (this.auth.countryCode == 'CO') {
								const httpOptions = {
									headers: new HttpHeaders({
										'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
									})
								};
								this.http.get(url, httpOptions)
									.subscribe((response) => {
										if (response['data']['status'] == 'APPROVED') {
											this.api.updateDocument('orders', order.$key, {
												'transaction_status': 'captured',
												'status': 'making'
											}).then(data => {
												order.transaction_status = 'captured';
												this.SwalService.fire(
													'Transacción Capturada',
													'Captura de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
												'error'
											)
										}
										loader.dismiss();
										resolve(true)
									}, err => {
										loader.dismiss();
										resolve(true)
									});
							} else if (this.auth.countryCode == 'DO') {
								this.http.get(url)
									.subscribe((response) => {
										// console.log("--->Recibimos");
										// console.log(response);

										if (response['Found'] == false) {
											this.api.updateDocument('orders', order.$key, {
												'transaction_status': 'captured',
												'status': 'making'
											}).then(data => {
												order.transaction_status = 'captured';
												this.SwalService.fire(
													'Transacción Capturada',
													'Captura de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
												'error'
											)
										}
										loader.dismiss();
										resolve(true)
									}, err => {
										loader.dismiss();
										resolve(true)
									});
							}
						} else {
							loader.dismiss();
							resolve(true)
						}
					});
				})
			})
		});
	}

	captureCash(order) {
		return new Promise((resolve, reject) => {
			this.loader().then(loader => {
				loader.present().then(() => {
					this.SwalService.fire({
						title: 'Esta seguro que deseas Aceptar el Pago?',
						text: "¡No podrás revertir esto!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Si, estoy seguro'
					}).then((result) => {

						if (result.value) {
							if (!order.hasOwnProperty('transaction_status') && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'HN')) {
								this.api.updateDocument('orders', order.$key, {
									'transaction_status': 'taken',
									'status': 'making',
									'account_waiter': this.auth.token
								}).then(data => {
									order.transaction_status = 'taken';
									this.SwalService.fire(
										'Pago Recibido',
										'Pago Aceptado, orden actualizada con exito',
										'success'
									)
								})
							}
							loader.dismiss();
							resolve(true)
						} else {
							loader.dismiss();
							resolve(false)
						}
					});
				})
			})
		});
	}

}
