import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { api } from 'src/environments/environment.prod';
const identifier = "token";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    arr: any[];

    currencyTemp: any;
    countryCodeTemp: any;
    flag: boolean = false
    // role: any;
    flagSuccess: boolean = false;
    disbaleNotification: boolean = false

    baseHeaders:any = {}

    constructor(
        public database: AngularFireDatabase,
        private angularAuth: AngularFireAuth,
        private router: Router,
        public db: AngularFirestore,
        public auth: AuthService,
		private http: HttpClient,

    ) {
        // this.setUp();
    }

    token: string = this.getTokenFromLocalStorage();
    countryCode: string = this.getCountryFromLocalStorage();
    currency: string = this.getCurrencyFromLocalStorage();
    role: string = this.getRoleFromLocalStorage();
    email: string = this.getEmailFromLocalStorage();
    storeKey: string = this.getStoreKeyFromLocalStorage();
    nameStore: string = this.getNameStoreFromLocalStorage();
    namePerson: string = this.getNamePerson();
    mainStore: string = this.getMainStoreFromLocalStorage();
    storeCode : string = this.getCodeStoreFromLocalStorage();
    chatSender : string = this.getChatSenderFromLocalStorage();

    // Informacion de SAP
    priceList:number = 7
    codeCellar:string = this.getCodeCellarFromLocalStorage();

    // priceList:number = this.getPriceListFromLocalStorage();
    // codeCellar:string = this.getCodeCellarFromLocalStorage();

    setNotification ( status: boolean) :void {
        // console.log(status)
        this.disbaleNotification = status
    }



    setUp() {
        this.token = this.getTokenFromLocalStorage();
        this.countryCode = this.getCountryFromLocalStorage();
        this.currency = this.getCurrencyFromLocalStorage();

        this.angularAuth.authState.subscribe((firebaseUser) => {
            if (firebaseUser) {

                this.db.collection('stores').ref
                    .where('email', '==', firebaseUser.email)
                    .get()
                    .then(conf_response => {
                        if (!conf_response.empty) {
                            localStorage.setItem('countryCode', conf_response.docs[0].data().countryCode)
                            localStorage.setItem(identifier, firebaseUser.uid);
                            this.token = firebaseUser.uid;
                            this.countryCode = conf_response.docs[0].data().countryCode;
                            this.currency = conf_response.docs[0].data().currency;
                            this.codeCellar = conf_response.docs[0].data().codeCellar !== undefined ?  conf_response.docs[0].data().codeCellar : ""

                            if (conf_response.docs[0].data().hasOwnProperty('users')) {
                                conf_response.docs[0].data().users.forEach(element => {
                                    if (element.email == firebaseUser.email) {
                                        localStorage.setItem('role', element.role)
                                        localStorage.setItem('priceList', element.priceList)
                                        localStorage.setItem('codeCellar', element.codeCellar)
                                        localStorage.setItem('email', element.email)
                                    }
                                });
                            } else {
                                localStorage.setItem('role', "404")
                            }
                        } else {
                            this.logOut();
                        }
                    })
            } else {
                localStorage.removeItem(identifier)
                this.token = null;
            }
        })
    }

    getNamePerson():string {
        return localStorage.getItem("namePerson") ? localStorage.getItem("namePerson") : ""
    }

    getTokenFromLocalStorage(): string {
        return localStorage.getItem(identifier)
    }

    getCountryFromLocalStorage(): string {
        return localStorage.getItem('countryCode')
    }

    getRoleFromLocalStorage(): string {
        return localStorage.getItem('role')
    }

    getEmailFromLocalStorage(): string {
        return localStorage.getItem('email')
    }

    getCurrencyFromLocalStorage() {
        return localStorage.getItem('currency')
    }

    getStoreKeyFromLocalStorage() {
        return localStorage.getItem('storeKey')
    }

    getNameStoreFromLocalStorage() {
        return localStorage.getItem('nameStore')
    }

    getMainStoreFromLocalStorage() {
        return localStorage.getItem('mainStore')
    }

    getCodeStoreFromLocalStorage() {
        return localStorage.getItem('storeCode')
    }

    getChatSenderFromLocalStorage() {
        return localStorage.getItem('chatSender')
    }

    getPriceListFromLocalStorage() {
        return parseInt(localStorage.getItem('priceList'))
    }
    
    getCodeCellarFromLocalStorage() {
        return localStorage.getItem('codeCellar')
    }
    login(logindata) {
        return new Promise((resolve, reject) => {
            this.angularAuth.auth.signInWithEmailAndPassword(logindata.email, logindata.password).then((data) => {

                resolve(null);
            }, (err) => {
                if (err.code === 'permission-denied') {
                    console.error('No tienes permiso para acceder a este documento');
                    reject(null);
                } else {
                    console.error(err);
                    reject(err);
                }
            })
        });
    }
  
    validateAccount(logindata) {
        return new Promise(async resolve => {
            this.token = '';
            this.countryCode = '';
            this.currency = '';

            this.currencyTemp = ''
            this.countryCodeTemp = ''

            this.angularAuth.authState.subscribe((firebaseUser) => {
                if (firebaseUser) {

                    localStorage.setItem(identifier, firebaseUser.uid);
                    this.token = firebaseUser.uid;
                    let body = { 
                        token: firebaseUser.uid,
                        data: {
                            email: firebaseUser.email
                        }
                    }

                    let header =  new HttpHeaders({
                        'Access-Control-Allow-Origin': 'origin',
                        'application': 'x-www-form-urlencoded',
                        'Content-type': 'application/json',
                        'Access-Control-Allow-Credentials': 'true',
                        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
                        'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
                    })

                    this.http.post<any>(api + "/accounts/get/store/information", body, { headers: header }).subscribe({
                        next: (response )=>{
                            this.flag = true
                            this.role = response.data.role
                            this.email = response.data.email
                            this.currencyTemp = response.data.currency
                            this.storeCode = response.data.code_store
                            this.countryCode = response.data.countryCode
                            this.storeKey = response.data.store
                            this.codeCellar = response.data.codeCellar ? response.data.codeCellar : ""
                            this.priceList = response.data.priceList ?  response.data.priceList : 0
                            this.countryCodeTemp  = response.data.countryCode
                            this.namePerson =  response.data.namePerson

                            localStorage.setItem('countryCode', this.countryCodeTemp)
                            localStorage.setItem('currency', this.currencyTemp)
                            localStorage.setItem('role', this.role)
                            localStorage.setItem('email', this.email)
                            localStorage.setItem('priceList', this.priceList.toString())
                            localStorage.setItem('codeCellar', this.codeCellar)
                            localStorage.setItem('namePerson', this.namePerson)
                            
                            resolve("exito");
                        }, 
                        error: (error) => {
                            console.warn(error)
                            resolve("fracaso");
                        }
                    })
                    return
                } else {
                    localStorage.removeItem(identifier)
                    this.token = null;
                }

            })

        });
    }


    // grabar log en base sobre el acceso
    saveLogLogin(token, message) {
        return new Promise((resolve, reject) => {
            this.db.collection(`users-stores`).doc(token)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    // agregando documento a la collection log init session
                    let batch = this.db.firestore.batch();
                    let obj = {
                        message,
                        date_session: new Date(),
                        uid: snapshot.id
                    }
                    let key_session = this.db.createId();
                    batch.set(this.db.firestore.collection(`users-stores/${snapshot.id}/logs_session/`).doc(key_session),
                        obj, { merge: true }
                    );
                    batch.commit().then(data => {
                        resolve('success');
                    })
                }).catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                });
        });
    }

    logOut() {
        return this.angularAuth.auth.signOut().then(() => {
            this.token = null;
            this.router.navigateByUrl('/login');
        });
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

}
